@import url(https://fonts.googleapis.com/css?family=Heebo:300,400,700,900|Roboto:300,400,500,700);
/*---------------------------------------------------- */
/*----------------------------------------------------*/

.app {
  overflow-x: hidden;
}
.list {
  list-style: none;
  margin: 0px;
  padding: 0px; }

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out; }
  a:hover, a:focus {
    text-decoration: none;
    outline: none; }

.row.m0 {
  margin: 0px; }

body {
  line-height: 26px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #777777; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Heebo", sans-serif;
  font-weight: bold; }

button:focus {
  outline: none;
  box-shadow: none; }

.p0 {
  padding-left: 0px;
  padding-right: 0px; }

.p_120 {
  padding-top: 120px;
  padding-bottom: 120px; }

.p_100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.pad_top {
  padding-top: 120px; }

.pad_bt {
  padding-bottom: 120px; }

.mt-25 {
  margin-top: 25px; }

.github_links {
  text-overflow: ellipsis;
  overflow: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } 
  }

@media (min-width: 1620px) {
  .box_1620 {
    max-width: 1620px;
    margin: auto;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px; } 
  
  }


/*---------------------------------------------------- */
/*----------------------------------------------------*/
/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  position: relative;
  z-index: 1;
  background-color: #333;
 }
  .home_banner_area .box_1620 {
    min-height: 500px;
    border-radius: 12px;
    position: relative;
    bottom: -50px;
    background: #fff;
    padding: 30px;
    box-shadow: 0px 20px 80px 0px rgba(153, 153, 153, 0.3); }
  .home_banner_area .banner_inner {
    position: relative;
    width: 100%;
    min-height: 500px;
    display: flex; }
    .home_banner_area .banner_inner .banner_content {
      color: #222222;
      vertical-align: middle;
      align-self: center;
      text-align: left; }
      .home_banner_area .banner_inner .banner_content .media .media-body {
        padding: 50px;
        vertical-align: middle;
        align-self: center; }

.headshot{
  height: 30%;
  width: 30%;
  align-self: center;
}

.mylink {
  text-decoration: none;
}

.links {
  flex-direction: row;
}

.blog_banner {
  min-height: 780px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 0px; }
  .blog_banner .banner_inner {
    background: #04091e;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 780px;
    z-index: 1; }
    .blog_banner .banner_inner .overlay {
      opacity: .5;
      height: 125%;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      z-index: -1; }
    .blog_banner .banner_inner .blog_b_text {
      max-width: 700px;
      margin: auto;
      color: #fff; }
      .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 60px;
        font-weight: bold;
        font-family: "Heebo", sans-serif;
        line-height: 66px;
        margin-bottom: 15px;
        text-transform: uppercase; }
      .blog_banner .banner_inner .blog_b_text p {
        font-size: 16px;
        margin-bottom: 35px; }
      .blog_banner .banner_inner .blog_b_text .white_bg_btn {
        line-height: 42px;
        padding: 0px 45px;
        border-radius: 5px; }

.banner_box {
  max-width: 1620px;
  margin: auto; }


.banner_area {
  position: relative;
  z-index: 1;
  min-height: 350px;
  background-image: -ms-linear-gradient(0deg, #2000af 0%, #006eff 100%);
  margin-bottom: 120px; }
  .banner_area .box_1620 {
    min-height: 360px;
    border-radius: 12px;
    position: relative;
    bottom: -120px;
    box-shadow: 0px 20px 80px 0px rgba(153, 153, 153, 0.3); }
  .banner_area .banner_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 360px;
    z-index: 1; }
    .banner_area .banner_inner .banner_content h2 {
      color: #222222;
      font-size: 48px;
      font-family: "Heebo", sans-serif;
      margin-bottom: 0px;
      font-weight: bold; }
    .banner_area .banner_inner .banner_content .page_link a {
      font-size: 14px;
      color: #222222;
      font-family: "Roboto", sans-serif;
      margin-right: 32px;
      position: relative; }
      .banner_area .banner_inner .banner_content .page_link a:before {
        content: "\e87a";
        font-family: 'Linearicons-Free';
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%); }
      .banner_area .banner_inner .banner_content .page_link a:last-child {
        margin-right: 0px; }
        .banner_area .banner_inner .banner_content .page_link a:last-child:before {
          display: none; }
      .banner_area .banner_inner .banner_content .page_link a:hover {
        color: #2000af; }

.project_imgs {
  height: 150px;
  padding: 25px 25px 25px 0px;
}

.feature_item {
    border-radius: 25px;
    padding: 25px;
    transition: all 0.3s ease-in-out;
}

.feature_item:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
}

/* End Home Banner Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/

/* Personal Profile Area css
============================================================================================ */
.profile_area .col-lg-7 {
  vertical-align: middle;
  align-self: center; }

.profile_inner {
  border-bottom: 1px solid #eeeeee; }
  .profile_inner .personal_text {
    overflow-wrap: normal;
    padding-left: 95px; }

.personal_text h6 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-weight: normal;
  margin-bottom: 12px;
  color: #222222; }
.personal_text h4 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #222222; }
.personal_text h3 {
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #222222; }
.personal_text .about_me {
  font-family: "Roboto", sans-serif;
  max-width: 540px;
  color: #777777;
  margin-bottom: 40px; }
.personal_text .basic_info li {
  margin-bottom: 15px; }
  .personal_text .basic_info li p {
    position: relative;
    font-size: 16px;
    color: #777777;
    margin-right: 20px;
   }
    .personal_text .basic_info li a {
      position: relative;
      font-size: 16px;
      color: #777777; }
    .personal_text .basic_info li p i {
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      color: #2000af; }
  .personal_text .basic_info li:last-child {
    margin-bottom: 0px; }
.personal_social {
  margin-top: 45px; }
  .personal_social li {
    display: inline-block;
    margin-right: 7px; }
    .personal_social li:last-child {
      margin-right: 0px; }
    .personal_social li a {
      line-height: 40px;
      width: 40px;
      background: #c0c0c0;
      border-radius: 5px;
      display: inline-block;
      text-align: center;
      color: #fff;
      font-size: 16px; }
    .personal_social li:hover a {
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    }

/* End Personal Profile Area css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/

@media(max-width:1000px) {
  .media {
      flex-direction: column;
  }
  .headshot{
    height: 70%;
    width: 70%;
    align-self: center;
  }
  .links {
    flex-direction: column;
  }
}
